.navbar-light .navbar-nav .nav-link:not(.active) {
  color: #ddd !important;
}
.nav-link.active:not([data-toggle='tab']) {
  color: #fff !important;
}
.ck.ck-editor__main {
  height: 100%;
}
.ck-editor__editable {
  height: 65%;
}
.ck.ck-editor {
  height: 100% !important;
}
.ck.ck-dropdown .ck-dropdown__arrow {
  z-index: unset !important;
}
.ck-content li {
  list-style-position: inside !important;
}
.ck-content ol li {
  list-style-type: decimal;
}
.ck-content ul li {
  list-style-type: disc;
}
